import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB3pMpCl6MRMSsk4fv0Rj348egJVCj9tRc",
    authDomain: "gpdotcom-3bef8.firebaseapp.com",
    databaseURL: "https://gpdotcom-3bef8-default-rtdb.firebaseio.com",
    projectId: "gpdotcom-3bef8",
    storageBucket: "gpdotcom-3bef8.appspot.com",
    messagingSenderId: "1096788338564",
    appId: "1:1096788338564:web:08a3821e717d511bf1dfd1",
    measurementId: "G-WYZ8BSW6C4"
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);