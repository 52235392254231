import React from "react";
import "./SchedulePage.css";

import ListGroup from "react-bootstrap/ListGroup";
import { Form, Image } from "react-bootstrap";

type scheduleProps = {
  isMobile: boolean;
}

function SchedulePage(props: scheduleProps) {
  const {isMobile} = props;
  function toFestivalSite(url: string): void {
    window.open(url);
  }

  return (
    <div className="root">
      <h1 className={isMobile ? "header-mobile" : "header-desktop"}>2024 Schedule (click for more information)</h1>
      <ListGroup>
        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() =>
            toFestivalSite(
              "https://www.trentonmi.org/763/Trenton-Summer-Festival"
            )
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Trenton Summer Festival (Trenton, MI)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>June 28th-30th </Form.Text>
          <br />
          <Image
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd2mkojm4rk40ta.cloudfront.net%2Fus-east-1-src%2Fprod%2FclientUploads%2F2023-03%2F16%2F1%2F55242%2F85864ea4-f3c9-44ac-b166-9c3c7ef008d0-bKe4lN.jpg&f=1&nofb=1&ipt=29f138c1d65b2639362299e8ec3c5e52476851114c2394b12cb1983de89e4178&ipo=images"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item  list-group-item-secondary m-1"
          action
          onClick={() =>
            toFestivalSite(
              "https://wyandotte.net/arts_and_culture/street_art_fair/index.php"
            )
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Wyandotte Street Art Fair (Wyandotte, MI)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>July 10th-13th</Form.Text>
          <br />
          <Image
            src="https://cms6.revize.com/revize/wyandottemi/2024%20WSAF%20Poster%20HTD%20Dark%20Blue%20-%20Copy.jpg"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() => toFestivalSite("https://artinthepark.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Art in the Park (Plymouth, MI)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>July 12th-14th </Form.Text>
          <Image
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fplymouthvoice.com%2Fwp-content%2Fuploads%2F2017%2F07%2FArt-in-the-Park-2017.png&f=1&nofb=1&ipt=15555a445f85dbd02011a972310a878a56843009cb8a81333dc68f6dfe18cf97&ipo=images"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item  list-group-item-secondary m-1"
          action
          onClick={() => toFestivalSite("https://www.theannarborartfair.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            {" "}
            Ann Arbour Art Fair (Ann Arbour, MI){" "}
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>July 18th-20th </Form.Text>
          <Image
            src="https://images.squarespace-cdn.com/content/v1/5871529cff7c50c708eadc27/9d2ae6b0-5206-4dbf-9e4c-114c3656bf78/DarkBackgroundLogo_WEB_AAAF.png?format=1500w"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() => toFestivalSite("https://www.ohiostatefair.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Ohio State Fair (Columbus, OH)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>July 24th-August 4th </Form.Text>
          <Image
            src="https://cdn.saffire.com/images.ashx?t=ig&rid=OhioStateFair&i=OSF_Logo_Horiz-Date(2).png"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-secondary m-1"
          action
          onClick={() => toFestivalSite("https://www.milfordmemories.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Milford Memories Summer Arts Festival (Milford, MI)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>August 9th-11th</Form.Text>
          <Image
            src="https://www.milfordmemories.com/wp-content/uploads/2019/01/LOGO-REFRESH-revised-VERT-4-e1547144970163-1024x197.png"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() =>
            toFestivalSite("https://www.clevelandoktoberfest.com/home/")
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            Oktoberfest (Cleveland, OH)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>
            August 30th-September 2nd, September 6th-7th{" "}
          </Form.Text>
          <Image
            src="https://www.clevelandoktoberfest.com/wp-content/uploads/2024/05/2024-okt-web-banner-lg-cropped-651-opt.png"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-secondary m-1"
          action
          onClick={() =>
            toFestivalSite("http://www.thornvillebackwoodsfest.com/")
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            The Backwoods Fest (Thornville, OH)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>September 20th-22nd</Form.Text>
          <Image
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flancasterherald.com%2Fwp-content%2Fuploads%2F2023%2F08%2FBackwoods-Fest-tractors-wagons.jpeg&f=1&nofb=1&ipt=5ca4040eaacf2c539619ef7bf304b1dbd0abfad953ad6e4d4d74679c40326e26&ipo=images"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() =>
            toFestivalSite("https://sauerkrautfestival.waynesvilleohio.com/")
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            The Sauerkraut Festival (Waynesville, OH){" "}
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>October 12th-13th </Form.Text>
          <Image
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Feventlas.com%2Fwp-content%2Fuploads%2F2023%2F05%2FOhio-Sauerkraut-Festival.jpg&f=1&nofb=1&ipt=bee3c6a644930cce0e655a4fdd4e51a68192cc1c9675ff2dbeeca274ebaaaaea&ipo=images"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item  list-group-item-secondary m-1"
          action
          onClick={() => toFestivalSite("https://mtdoracraftfair.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            {" "}
            Mt. Dora Craft Fair (Mt. Dora, FL)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>October 26th-27th</Form.Text>
          <Image
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftours.yankeetrails.com%2Fdataimages%2Fbriefoverview%2F102022_mountdoracraftfestival.jpeg&f=1&nofb=1&ipt=e5a8df03bedd24aab13822cd2055b380abc88925635e81e9d2e478c1537aa908&ipo=images"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-primary m-1"
          action
          onClick={() => toFestivalSite("https://www.halifaxartfestival.com/")}
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            {" "}
            Halifax Art Festival (Daytona, FL)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>November 2nd-3rd </Form.Text>
          <Image
            src="https://www.halifaxartfestival.com/wp-content/themes/artfestival/images/headerLogo.jpg"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>

        <ListGroup.Item
          className="list-group-item list-group-item-secondary m-1"
          action
          onClick={() =>
            toFestivalSite("https://www.clevelandchristmasconnection.com/")
          }
        >
          <Form.Text className={isMobile ? "festivalText-mobile" : "festivalText"}>
            {" "}
            The Christmas Connection (IX Center, Cleveland, OH)
          </Form.Text>{" "}
          <Form.Text className={isMobile ? "dateText-mobile" : "dateText"}>November 22nd-24th </Form.Text>
          <Image
            src="https://cdn.marketplaceevents.com/sitefinity/images/librariesprovider128/homepage/christmas-connection.jpg?sfvrsn=27710e3e_0)"
            thumbnail
            className={isMobile ? "img-mobile" : "img"}
          />
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export default SchedulePage;
