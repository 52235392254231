import React from "react";

import "./LandingPage.css";

import home from "../../../imgs/home.jpeg";
import home2 from "../../../imgs/home2.jpeg";
import { Brush, Palette, Envelope } from "react-bootstrap-icons";

import { Button } from "react-bootstrap";

type landingProps = {
  setShowModal: (show:boolean) => void;
  isMobile: boolean;
}

function LandingPage(props: landingProps) {
  const {isMobile} = props;
const { setShowModal} = props;

  return (
    <div className="root">
      <div className="container">
        <svg viewBox="0 0 960 200">
          <symbol id="s-text">
            <text textAnchor="middle" x="50%" y="75%">
              Welcome
            </text>
          </symbol>

          <g className="g-ants">
            <use xlinkHref="#s-text" className="text-copy"></use>
            <use xlinkHref="#s-text" className="text-copy"></use>
            <use xlinkHref="#s-text" className="text-copy"></use>
            <use xlinkHref="#s-text" className="text-copy"></use>
            <use xlinkHref="#s-text" className="text-copy"></use>
          </g>
        </svg>
      </div>
      <Button className="emailButton2" onClick={() => setShowModal(true)}><p style={{color: '#0A7983'}}>Click here to signup for my email list! Check the first email you get for a special surprise :)</p></Button>
      <p className={isMobile ? "bio-mobile" : "bio"}>
        Hello I'm Gerri! I am a career jeweler, painter, cartoonist, designer, and
        the 100 other titles I've carried in the decades I've been creating art.
        My passion has always been art, bringing joy, creativity, and
        enterntainment. From cartooning at the LA Times and Paradise Promotions
        in the 80's to slinging tshirts on springbreaks, I have done it all with
        humor and ON OCCASION grace :p <br />
        Decades of experience creating jewelry, glass, and various forms of
        mixed media, my current passion in art is pastels, paints, and pencils.
      </p>
      <img className={isMobile ? "homeImage-mobile" : "homeImage"} src={home} alt="home image" />

      <p className={isMobile ? "bio-mobile" : "bio"}>
        I've set up this website as a portfolio for my newest projects as well
        as a way for the many wonderful people I've met over the years and will
        continue to meet to see my yearly schedule and keep in touch. <br />
        {!isMobile && <span>
          <Brush className="icon" />
        </span>}
        At the top of this page are links to my jewelry and art pages as well as
        a menu to navigate to a page of current projects and my schedule for the
        year.
        <br />
        {!isMobile && <span>
          <Palette className="icon" />
        </span>}
        If you like my art PLEASE join my email list and never miss an update on
        where I will be! I travel the country year round selling my art and
        jewelry at various art shows and venues. You can click the button at the
        bottom of the page or in the menu on the primary toolbar to join me on
        my artist's journey.
        {!isMobile && <span>
          <Envelope className="icon" />
        </span>}
      </p>
      <Button className="emailButton" onClick={() => setShowModal(true)}><p style={{color: '#0A7983'}}>Join the Email List!</p></Button>
      <img className={isMobile ? "homeImage-mobile" : "homeImage"} src={home2} alt="home image 2" />
    </div>
  );
}

export default LandingPage;
