import './PortfolioPage.css';

import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import art1 from "../../../imgs/art1.jpeg";
import art4 from "../../../imgs/art4.jpeg";
import art3 from "../../../imgs/art3.jpeg";
import art5 from "../../../imgs/art5.jpeg";
import client1 from "../../../imgs/client1.jpeg";
import client2 from "../../../imgs/client2.jpeg";
import home2 from '../../../imgs/home2.jpeg';

type portfolioProps = {
  isMobile: boolean;
}

function PortfolioPage(props: portfolioProps) {
  const {isMobile} = props;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className={isMobile ? "carousel-mobile" : "carousel"}>
        
      <Carousel.Item >
        <Image src={art1} className='carouselImg' alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE</h3>
          <p className="artDescription">DESCRIPTION</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item >
        <Image src={client1} className='carouselImg' alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 2</h3>
          <p className="artDescription">DESCRIPTION 2</p>
        </Carousel.Caption> */}
      </Carousel.Item> 

      <Carousel.Item className="carousel-item">
        <Image src={art4} className="carouselImg" alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 3</h3>
          <p className="artDescription">DESCRIPTION 3</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item >
        <Image src={client2} className='carouselImg' alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 4</h3>
          <p className="artDescription">DESCRIPTION 4</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item className="carousel-item">
        <Image src={art3} className="carouselImg" alt="..." />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 5</h3>
          <p className="artDescription">
          DESCRIPTION 5
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item >
        <Image src={art5} className='carouselImg' alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 6</h3>
          <p className="artDescription">DESCRIPTION 6</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      <Carousel.Item >
        <Image src={home2} className='carouselImg' alt='...' />
        {/* <Carousel.Caption style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
          <h3 className="artLabel">TITLE 7</h3>
          <p className="artDescription">DESCRIPTION 7</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default PortfolioPage;
