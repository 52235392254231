import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { emailBuilder } from "./emailBuilder";

export const submitToDB = async (nClient: any, id: string) => {
 let htmlMSG = emailBuilder(nClient);
  await setDoc(doc(db, "gpClients", id), {
    Name: nClient.name,
    Email: nClient.email,
    State: nClient.state,
    alwaysNotify: nClient.alwaysNotify,
  }).then((resp) => {
    return;
  });
  await setDoc(doc(db, "mail", id), {
    to: nClient.email,
    message: {
      subject: "Thank you " + nClient.name + "! :)",
      text: "Thanks for joining my email list! The only emails you will see from me are regarding where I will be selling my art, no spam :). As extra thanks, use the code word DIVERSIFIED for 10% off any sales at my summer locations.",
      html: htmlMSG,
    },
  }).then((resp) => {return});
};

