type client = {
    name: string;
    email: string;
    state: string; 
    alwaysNotify: boolean;
}

export const emailBuilder = (nClient: client): string => {
const {alwaysNotify} = nClient;
let htmlMSG = "";
if(alwaysNotify){
     htmlMSG = `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8"> 
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>
         Thanks for signing up ${nClient.name}
    </title> 
    <style>
    @media screen and (max-width: 600px) { .content { width: 100% !important; display: block !important; padding: 10px !important; } .header, .body, .footer { padding: 20px !important; } } 
    </style> 
    </head> 
        <body style="font-family: "Poppins", Arial, sans-serif"> 
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
        <td align="center" style="padding: 20px;"> 
        <table class="content" width="600" border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: 1px solid #cccccc;">
        
        <!-- Header -->
        <tr>
        <td class="header" style="background-color: #345C72; padding: 40px; text-align: center; color: white; font-size: 24px;">
            Thank you for joining me on my Artist's Journey... 
        </td>
        </tr>
                 
        <!-- Body --> 
                 
        <tr>
        <td class="body" style="padding: 40px; text-align: left; font-size: 16px; line-height: 1.6;">
            Welcome ${nClient.name}! <br> You shouldn't be seeing too many emails from me throughout
            the year, but you will be notified any time I am selling my art with the location and dates it will 
            be there. <br><br>
            As an extra thank you for signing up, you can use the code word <br/><br/>
                DIVERSIFIED <br/><br/> 
            at any location I am selling my art this year for an additional 10% off,
            inlcuding items already on sale. Thank you again for joining the email list and I hope I get the chance to see you 
            out this year :)
            <br/><br/>
            -Gerri
            </td>
            </tr>
                        
        <!-- Footer --> 
        <tr>
        <td class="footer" style="background-color: #333333; padding: 40px; text-align: center; color: white; font-size: 14px;">
            Copyright &copy; 2024 | Gerri Pizzini - Artist </td>
        </tr>
        </table> 
        </td> 
        </tr> 
        </table> 
        </body>
        </html>`;
}
else {
    htmlMSG = `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8"> 
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>
         Thanks for signing up ${nClient.name}
    </title> 
    <style>
    @media screen and (max-width: 600px) { .content { width: 100% !important; display: block !important; padding: 10px !important; } .header, .body, .footer { padding: 20px !important; } } 
    </style> 
    </head> 
        <body style="font-family: "Poppins", Arial, sans-serif"> 
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
        <td align="center" style="padding: 20px;"> 
        <table class="content" width="600" border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: 1px solid #cccccc;">
        
        <!-- Header -->
        <tr>
        <td class="header" style="background-color: #345C72; padding: 40px; text-align: center; color: white; font-size: 24px;">
            Thank you for joining me on my Artist's Journey... 
        </td>
        </tr>
                 
        <!-- Body --> 
                 
        <tr>
        <td class="body" style="padding: 40px; text-align: left; font-size: 16px; line-height: 1.6;">
            Welcome ${nClient.name}! <br> You shouldn't be seeing too many emails from me throughout
            the year, you will only be notified if I am displaying and selling art within your state. <br><br>
            As an extra thank you for signing up, you can use the code word <br/><br/>
                DIVERSIFIED <br/><br/> 
            at any location I am selling my art this year for an additional 10% off,
            inlcuding items already on sale. Thank you again for joining the email list and I hope I get the chance to see you 
            out this year :)
            <br/><br/>
            -Gerri
            </td>
            </tr>
                        
        <!-- Footer --> 
        <tr>
        <td class="footer" style="background-color: #333333; padding: 40px; text-align: center; color: white; font-size: 14px;">
            Copyright &copy; 2024 | Gerri Pizzini - Artist </td>
        </tr>
        </table> 
        </td> 
        </tr> 
        </table> 
        </body>
        </html>`;
}
    
    return htmlMSG;
}