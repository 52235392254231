import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import PrimaryToolbar from "./Components/PrimaryToolbar/PrimaryToolbar";
import LandingPage from "./Components/Pages/LandingPage/LandingPage";
import PortfolioPage from "./Components/Pages/PortfolioPage/PortfolioPage";
import SchedulePage from "./Components/Pages/SchedulePage/SchedulePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


function App() {
  const [showEmailModal, setShowEmailModal] = React.useState(false);

  const [mobile, setMobile] = React.useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  return (
    <div className="root">
      <PrimaryToolbar
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        isMobile={mobile}
      />
      <Router>
        
        <Routes>
          <Route path="/" Component={() => <LandingPage setShowModal={setShowEmailModal} isMobile={mobile}/>} />
          <Route path="/home" Component={() => <LandingPage  setShowModal={setShowEmailModal} isMobile={mobile}/>} />
          <Route path="/art" Component={() => <PortfolioPage isMobile={mobile}/>} />
          <Route path="/schedule" Component={() => <SchedulePage isMobile={mobile}/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
