import "./EmailService.css";

import React from "react";

import Button from "react-bootstrap/Button";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";

import { submitToDB } from "./model";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";


const statesABB: string[] = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
const statesFull: string[] = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type emailProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  isMobile: boolean;
};

function EmailService(props: emailProps) {
  const { showModal, setShowModal, isMobile } = props;
  const [cList, setCList] = React.useState<any[]>([]);
  const [listRetrieved, setListRetrieved] = React.useState<boolean>(false);

  const [nClient, setNClient] = React.useState<{
    name: string;
    email: string;
    state: string;
    alwaysNotify: boolean;
  }>({ name: "", email: "", state: "", alwaysNotify: false });

  const [eList, setEList] = React.useState<string[]>([]);

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertMsg, setAlertMsg] = React.useState<{ type: string; msg: string }>(
    { type: "Warning", msg: "It appears something has gone wrong..." }
  );

  React.useEffect(() => {
    async function getSnap() {
      let c: any[] = [];
      let e: string[] = [];
      const querySnapshot = await getDocs(collection(db, "gpClients"));
      querySnapshot.forEach((doc) => {
        c.push(doc.data());
      });
      c.forEach((o) => e.push(o.Email as string));
      setEList(e);
      setCList(c);
      setListRetrieved(true);
    }
    if (!listRetrieved) {
      getSnap();
    }
  }, [listRetrieved]);

  React.useEffect(() => {
    if (showModal === false)
      setNClient({ name: "", email: "", state: "", alwaysNotify: false });
  }, [showModal]);

  const handleSubmit = () => {
    let valid = validate(nClient.email, nClient.state);

    if (valid) {
      let id = String(cList.length + 1);
      submitToDB(nClient, id).then((e) => setShowModal(false));
      setListRetrieved(false);
    }
  };

  const validate = (email: string, state: string): boolean => {
    if (!re.test(email)) {
      setAlertMsg({
        type: "That doesn't appear to be a valid email...",
        msg: "It looks like you haven't input a proper email address, please enter a valid and active email, thank you.",
      });
      setShowAlert(true);
      return false;
    }
    if (eList.includes(email)) {
      setAlertMsg({
        type: "It looks like you've already joined :)",
        msg: "You should already be receiving updates from my personal email, if this is not the case please reach out to me through my facebook page.",
      });
      setShowAlert(true);
      return false;
    }
    if (!statesABB.includes(state) && !statesFull.includes(state)) {
      setAlertMsg({
        type: "There appears to be an issue with the State...",
        msg: "Please enter a valid State Abbreviation or the full State name :)",
      });
      setShowAlert(true);
      return false;
    }

    return true;
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter m-auto"
      centered
      contentClassName="emailContent"
    >
      <Alert
        show={showAlert}
        variant="warning"
        onClose={() => setShowAlert(false)}
        dismissible
      >
        <Alert.Heading>{alertMsg.type}</Alert.Heading>
        <p>{alertMsg.msg}</p>
      </Alert>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span style={{color: '#95F8F1'}}>I just need a few items from you...</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className={isMobile ? "inputGroup-mobile" : "mb-3 d-flex"}
          >
            <Form.Label
              className={isMobile ? "text-style-mobile" : "text-style"}
            >
              What is your name?
            </Form.Label>
            <Form.Control
              placeholder="Name"
              aria-label="Name"
              aria-describedby="basic-addon1"
              value={nClient.name}
              onChange={(e) => {
                setShowAlert(false);
                setNClient({ ...nClient, name: e.target.value })}}
              style={{ width: "100%", fontSize: isMobile ? "8pt" : "14pt" }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label
              className={isMobile ? "text-style-mobile" : "text-style"}
            >
              What is your email?
            </Form.Label>
            <Form.Control
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              type="email"
              value={nClient.email}
              onChange={(e) =>
                setNClient({ ...nClient, email: e.target.value })
              }
              style={{ width: "100%", fontSize: isMobile ? "8pt" : "14pt" }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label
              className={isMobile ? "text-style-mobile" : "text-style"}
            >
              What state are you located in?
            </Form.Label>
            <Form.Control
              placeholder="State"
              aria-label="State"
              aria-describedby="basic-addon1"
              value={nClient.state}
              onChange={(e) =>{
                setShowAlert(false);
                setNClient({ ...nClient, state: e.target.value })}
              }
              style={{ width: "100%", fontSize: isMobile ? "8pt" : "14pt" }}
            />
          </Form.Group>

          <Form.Label
            className={isMobile ? "text-style-mobile" : "text-style"}
          >
            Recieve all updates or only those in your area?
          </Form.Label>
          <Form.Check
            defaultChecked={true}
            name="radios"
            label="No thank you, only when you are in my State."
            style={{ margin: 3, color:'#95F8F1' }}
            type={"radio"}
            onClick={() => {
              setShowAlert(false);
              setNClient({ ...nClient, alwaysNotify: false })}}
          />
          <Form.Check
            name="radios"
            label="Always let me know where you're at!"
            style={{ margin: 3, color: '#95F8F1' }}
            type={"radio"}
            onClick={() => setNClient({ ...nClient, alwaysNotify: true })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)} style={{backgroundColor: "#95F8F1", color: '#0A7983'}}>Close</Button>
          <Button onClick={handleSubmit} style={{backgroundColor: "#95F8F1", color: '#0A7983'}}>Join!</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EmailService;
