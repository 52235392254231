import './PrimaryToolbar.css';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import EmailService from '../EmailService/EmailService';

import './PrimaryToolbar.css';
import { Form } from 'react-bootstrap';

type tbProps = {
  showModal: boolean
  setShowModal: (show:boolean) => void;
  isMobile: boolean;
}

 function PrimaryToolbar(props: tbProps)  {
   let {showModal, setShowModal, isMobile} = props;

    return(
      <>
        <Navbar expand="lg" className={isMobile ? "root2-mobile" : "root2"}>
        <div className={isMobile ? "toolbar-mobile" : "toolbar"} style={{width:"100%"}}>
          <Navbar.Brand href="home" className="navbar-brand mx-auto" ><Form.Text className={isMobile ? "toolbarText-mobile" : "toolbarText"}>Gerri Pizzini - Artist</Form.Text></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{width:"100%"}}>
              <Nav.Link href="https://www.instagram.com/empoweredartbygp/"><Form.Text className="toolbarText">Instagram</Form.Text></Nav.Link>
              <Nav.Link href="https://www.facebook.com/JewelrybyGP"><Form.Text className="toolbarText">Facebook</Form.Text></Nav.Link>
              <NavDropdown title="More" id="basic-nav-dropdown" className="toolbarText2">
                <NavDropdown.Item href="home" style={{color: '#0A7983'}}>Home</NavDropdown.Item>
                <NavDropdown.Item href="art" style={{color: '#0A7983'}}>Portfolio</NavDropdown.Item>
                <NavDropdown.Item href="schedule" style={{color: '#0A7983'}}>
                Schedule
                </NavDropdown.Item>
                <Button onClick={() => setShowModal(true)} style={{color: '#0A7983'}} variant="outline-info" className="btn-toolbar-dropdown">Join the Email List!</Button>               
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <EmailService showModal={showModal} setShowModal={setShowModal} isMobile={isMobile}/>
      </>
    );
}

export default PrimaryToolbar;